import mkg_gmk_dolch from "./usercolorways6/mkg_gmk_dolch.json";
import mkg_gmk_fundamentals from "./usercolorways6/mkg_gmk_fundamentals.json";
import mkg_gmk_hyperfuse_origin_r2 from "./usercolorways6/mkg_gmk_hyperfuse_origin_r2.json";
import mkg_gmk_hyperfuse_r2 from "./usercolorways6/mkg_gmk_hyperfuse_r2.json";
import mkg_gmk_mercury from "./usercolorways6/mkg_gmk_mercury.json";
import mkg_gmk_monochrome_dolch from "./usercolorways6/mkg_gmk_monochrome_dolch.json";
import mkg_gmk_norse from "./usercolorways6/mkg_gmk_norse.json";
import mkg_gmk_rubrehose from "./usercolorways6/mkg_gmk_rubrehose.json";
import mkg_gmk_thinkcaps from "./usercolorways6/mkg_gmk_thinkcaps.json";
import mkg_gmk_trader_boomer from "./usercolorways6/mkg_gmk_trader_boomer.json";
import mkg_gmk_trader_zoomer from "./usercolorways6/mkg_gmk_trader_zoomer.json";

export const COLORWAYS_NEW = {
    mkg_gmk_dolch: mkg_gmk_dolch,
    mkg_gmk_fundamentals: mkg_gmk_fundamentals,
    mkg_gmk_hyperfuse_origin_r2: mkg_gmk_hyperfuse_origin_r2,
    mkg_gmk_hyperfuse_r2: mkg_gmk_hyperfuse_r2,
    mkg_gmk_mercury: mkg_gmk_mercury,
    mkg_gmk_monochrome_dolch: mkg_gmk_monochrome_dolch,
    mkg_gmk_norse: mkg_gmk_norse,
    mkg_gmk_rubrehose: mkg_gmk_rubrehose,
    mkg_gmk_thinkcaps: mkg_gmk_thinkcaps,
    mkg_gmk_trader_boomer: mkg_gmk_trader_boomer,
    mkg_gmk_trader_zoomer: mkg_gmk_trader_zoomer
};